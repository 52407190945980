import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import router from '../router';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    home: '',
    // url: 'http://localhost:3000',
    // url: 'http://interniapi.k-fx-server1.usermd.net',
    url: 'https://panel.interni-cargo.eu',
    // url: 'https://staging.interni-cargo.eu/panel',
    status: localStorage.getItem('token') ? 'success' : null,
    token: localStorage.getItem('token') || '',
    user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {},
    first_login: localStorage.getItem('first_login'),
    maps: {
      key: 'oMKNTCB9ysOwoLGGzzrCH73yJtyaVIEz',
      lang: 'pl-PL',
    },
  },
  mutations: {
    auth_request(state) {
      state.status = 'loading';
    },
    auth_success(state, { token, user, first_login }) {
      state.status = 'success';
      state.token = token;
      state.user = user;
      state.first_login = first_login;
      //window.location.reload();
    },
    auth_error(state, message) {
      state.status = 'error';
      state.message = message;
    },
    logout(state) {
      state.status = null;
      state.token = '';
      state.user = {};
      //window.location.reload();
    },
  },
  actions: {
    login({ commit, state }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request');

        let url = `${state.url}/api/customer/login`;
        if (user.type == 2) url = `${state.url}/api/admin/login`;

        axios({
          url: url,
          data: user,
          method: 'POST',
        })
          .then(resp => {
            if (resp.data.status === 200) {
              const token = resp.data.token;
              const user = resp.data.user;
              const first_login = resp.data.first_login;
              commit('auth_success', {
                token: token,
                user: user,
                first_login: first_login,
              });
              localStorage.setItem('token', token);
              localStorage.setItem('user', JSON.stringify(user));
              localStorage.setItem('first_login', first_login);

              resolve(resp);
            } else {
              commit('auth_error', resp.data.message);
              reject(resp.data.message);
            }
          })
          .catch(() => {
            commit('auth_error', 'Error');
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('first_login');
            reject('Nieoczekiwany błąd. Odśwież stronę i spróbuj ponownie');
          });
      });
    },
    logout({ commit }) {
      return new Promise(resolve => {
        let tmp = this.getters.isAdmin ? '/admin' : '/login';
        commit('logout');
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('first_login');
        router.push(tmp);
        resolve();
      });
    },
  },
  getters: {
    isFirstLogin: state => state.first_login == 'true' || state.first_login == true,
    isLoggedIn: state => state.status === 'success',
    isAdmin: state => (state.user && state.user.b === 233 ? true : false),
    initials: state => {
      return state.user.name ? state.user.name.substr(0, 1) + state.user.surname.substr(0, 1) : '';
    },
    identifier: state => (state.user ? state.user.id : null),
  },
  modules: {},
});
